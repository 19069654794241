import './composition.scss';
import Swiper from 'swiper';
import { A11y, Navigation, Pagination, EffectFade, Keyboard } from 'swiper/modules';

class Composition {
    constructor (element) {
        this.$slider = element;
        this.$mobileSlider = this.$slider.querySelectorAll('[data-slider-mobile="root"]');
        this.mobileSliderActive = false;
        this.$desktopSlider = this.$slider.querySelectorAll('[data-slider-desktop="root"]');
        this.desktopSliderActive = false;
        this.mobileSlider = [];
        this.desktopSlider = [];
    }

    initialize () {
        this.setSliderEvents();
        this.setSlider();
    }

    setSlider () {
        if (window.innerWidth < 1024) {
            this.loadMobileSlider();
        } else {
            this.loadDesktopSlider();
        }
    }

    // set resize event to all sliders
    setSliderEvents () {
        window.resizeHandler.customFunctions.push(() => {
            this.loadMobileSlider();
            this.loadDesktopSlider();
        });
    }

    // check length of mobile slider then init or destroy
    loadMobileSlider () {
        for (let i = 0; i < this.$mobileSlider.length; i++) {
            const slideCount = this.$mobileSlider[i].querySelectorAll('[data-slider-mobile="slide"]').length;
            if (window.innerWidth < 1024 && slideCount >= 1) {
                if (!this.mobileSliderActive) {
                    this.initMobileSlider();
                }
            } else {
                this.destroyMobileSlider();
            }
        }
    }

    // check length of desktop slider then init or destroy
    loadDesktopSlider () {
        for (let i = 0; i < this.$desktopSlider.length; i++) {
            const slideCount = this.$desktopSlider[i].querySelectorAll('[data-slider-desktop="slide"]').length;

            if (window.innerWidth >= 1024 && slideCount >= 1) {
                if (!this.desktopSliderActive) {
                    this.initDesktopSlider();
                }
            } else {
                this.destroyDesktopSlider();
            }
        }
    }

    // init swiper for mobile
    initMobileSlider () {
        for (let i = 0; i < this.$mobileSlider.length; i++) {
            this.mobileSlider[i] = new Swiper(this.$mobileSlider[i], {
                modules: [A11y, Keyboard],
                a11y: true,
                lazyPreloadPrevNext: true,
                keyboard: true,
                direction: 'horizontal',
                slidesPerView: 'auto',
                spaceBetween: 9,
                allowTouchMove: true,
                speed: 500
            });
        }
        this.mobileSliderActive = true;
    }

    // init swiper for desktop
    initDesktopSlider () {
        for (let i = 0; i < this.$desktopSlider.length; i++) {
            this.desktopSlider[i] = new Swiper(this.$desktopSlider[i], {
                modules: [A11y, Navigation, Pagination, EffectFade, Keyboard],
                a11y: true,
                lazyPreloadPrevNext: true,
                keyboard: true,
                effect: 'fade',
                direction: 'horizontal',
                slidesPerView: 'auto',
                speed: 350,
                autoHeight: true,
                spaceBetween: 50,
                allowTouchMove: true,
                navigation: {
                    nextEl: '.composition__next',
                    prevEl: '.composition__prev'
                },
                breakpoints: {
                    1024: {
                        allowTouchMove: false
                    }
                },
                pagination: {
                    el: '.composition__pagination',
                    type: 'fraction',
                    renderFraction: function (currentClass, totalClass) {
                        return '<span class="' + currentClass + '"></span>' + ' <span class="separator"></span> ' + '<span class="' + totalClass + '"></span>';
                    }
                }
            });

            this.desktopSlider[i].on('transitionEnd', () => {
                const activeIndex = this.desktopSlider[i].activeIndex;
                const activeSlide = this.desktopSlider[i].slides[activeIndex];
                const images = activeSlide.querySelectorAll('[data-animate="image"]');
                let index = 0;
                const interval = setInterval(() => {
                    images[index++].classList.add('is--active');

                    if (index === images.length) {
                        clearInterval(interval);
                    }
                }, 250);

                setTimeout(() => {
                    activeSlide.querySelector('[data-animate="headline"]').classList.add('is--active');
                }, 150);

                setTimeout(() => {
                    activeSlide.querySelector('[data-animate="copy"]').classList.add('is--active');
                    activeSlide.querySelector('[data-animate="cta"]').classList.add('is--active');
                }, 650);
            });

            this.desktopSlider[i].on('transitionStart', () => {
                const activeIndex = this.desktopSlider[i].activeIndex;
                const activeSlide = this.desktopSlider[i].slides[activeIndex];
                const images = activeSlide.querySelectorAll('[data-animate="image"]');

                for (let index = 0; index < images.length; index++) {
                    images[index].classList.remove('is--active');
                }

                activeSlide.querySelector('[data-animate="headline"]').classList.remove('is--active');
                activeSlide.querySelector('[data-animate="copy"]').classList.remove('is--active');
                activeSlide.querySelector('[data-animate="cta"]').classList.remove('is--active');
            });
        }

        this.desktopSliderActive = true;
    }

    // destroy mobile slider array
    destroyMobileSlider () {
        for (let i = 0; i < this.mobileSlider.length; i++) {
            if (this.mobileSlider[i] !== null) {
                this.mobileSlider[i].destroy(true, true);
                this.mobileSlider[i] = null;
            }
        }
        this.mobileSliderActive = false;
    }

    // destroy desktop slider array
    destroyDesktopSlider () {
        for (let i = 0; i < this.desktopSlider.length; i++) {
            if (this.desktopSlider[i] !== null) {
                this.desktopSlider[i].destroy(true, true);
                this.desktopSlider[i] = null;
            }
        }
        this.desktopSliderActive = false;
    }
}

export { Composition };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-composition="root"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new Composition($root);
                $rootAPI.initialize();
            });
        }
    }
});
